@import "stylesheets/animations.scss";

.card {
    width: 100%;
    max-width: var(--max-width-narrow);
    margin: 0 auto;
    padding: 4rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    box-shadow: var(--shadow-card);
    animation: slideIn 0.4s forwards;
    animation-delay: 0.1s;
    opacity: 0;

    a,
    a:focus,
    a:active {
        color: var(--color-3);
    }

    &:nth-child(n+2) {
        margin-top: 5rem;
        animation-delay: 0.25s;
    }
}

.title {
    display: flex;
    align-items: center;
    margin: 0 0 1.5rem 0;
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--color-2-dark);

    span {
        margin-left: 1rem;
    }
}