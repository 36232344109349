.title {
    margin-bottom: 2rem;
}

.infoBox {
    width: 100%;
    max-width: var(--max-width-narrow);
    margin: 0 auto 3rem auto;
}

.caption {
    margin: 2rem 0;

    + textarea {
        height: 50rem;
        margin-bottom: 2rem;
    }
}

.message {
    width: 25rem;
    padding: 0.5rem 1rem;
    margin: 0 auto 2rem auto;
    border-radius: var(--border-radius);
    background-color: var(--color-5-light);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-5);
}