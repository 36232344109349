@import "stylesheets/animations";

.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    background-color: var(--color-2);

    .content {
        width: 100%;
        max-width: var(--max-width);
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        animation: slideIn 0.7s forwards;
    }

    img {
        width: auto;
        height: 4rem;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li {
        margin-left: 2.5rem;
        transition: color 0.2s;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
    }

    a:hover,
    li:hover {
        color: var(--color-3);
    }
}

@media screen and (min-width: 576px) {
    .nav {
        img {
            height: 4.5rem;
        }

        li {
            font-size: 1.8rem;
        }
    }
}