@import "stylesheets/animations";

.sidebar {
    position: relative;
    width: var(--sidebar-width);
    min-height: 100%;
    flex-shrink: 0;
    padding: 0 1.5rem;
    background-color: var(--color-2-dimmed);
    animation: slideIn 0.7s forwards;
    z-index: 1;
}

.content {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1.5rem;
    color: var(--color-2-dark);
    -ms-overflow-style: none;
    overflow-y: auto;

    a {
        transition: color 0.4s;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.title {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;

    &:last-of-type {
        margin-top: 2.5rem;
    }
}

.site,
.siteActive {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--border-radius);
    margin: calc(var(--nav-height) + 1rem) 0 4rem 0;
    padding: 0.4rem;
    transition: background-color 2s;
    color: var(--color-2-dark);
    cursor: pointer;

    .icon {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: var(--color-3);
        text-transform: uppercase;
        font-size: 1.8rem;
        color: var(--color-2);

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--color-2-darker);
            object-fit: cover;
        }
    }

    .caption {
        + div,
        div:first-child {
            width: 11rem;
            margin-bottom: 0.2rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 1.5rem;
            font-weight: 500;
            transition: color 0.4s;
            color: var(--color-1);
            overflow: hidden;
        }

        div:last-child {
            font-size: 1.2rem;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: var(--color-1);
        transition: background-color 0.4s;
        color: var(--color-2);

        .caption {
            + div,
            div:first-child {
                color: var(--color-2);
            }
        }
    }
}

.siteActive {
    background-color: var(--color-1);
    color: var(--color-2);

    .caption {
        + div,
        div:first-child {
            color: var(--color-2);
        }
    }
}

.menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    animation: slideInLeft 0.5s;

    li {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        padding: 0.7rem 0.5rem 0.7rem 1rem;
        border-radius: var(--border-radius);
        transition: background-color 0.2s, color 0.2s;
        font-size: 1.7rem;
        cursor: pointer;

        span {
            height: 1.5rem;
            display: inline-block;
            margin-left: 0.8rem;
            font-size: 1.5rem;
        }

        &:hover {
            background-color: var(--color-3);
            color: var(--color-3-light);
        }
    }
}

.active {
    background-color: var(--color-3);
    color: var(--color-3-light);
}