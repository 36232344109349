.message {
    width: 100%;
    max-width: 40rem;
    margin: 12rem auto 0 auto;

    h3 {
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 3rem;
    }

    a {
        color: var(--color-2);
    }
}

.spinner {
    width: 100%;
    height: 40rem;
}