.spinner {
    position: relative;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--color-2);
        opacity: 0.5;
        animation: bounce 2.4s ease-in-out infinite;
    }

    div:last-child {
        animation-delay: -1.2s;
    }
}

.medium {
    @extend .spinner;
    width: 3.5rem;
    height: 3.5rem;
}

.invert {
    div {
        background-color: var(--color-3);
    }
}

.loadingSpinner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;

    > div {
        margin-bottom: 1rem;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}