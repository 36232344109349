.header {
    width: 100%;
    display: flex;
    margin-bottom: 5rem;

    h4 {
        margin-bottom: 0.5rem;
    }
}

.avatar {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: var(--color-3);
}

.name {
    margin: 1rem 0 0 3rem;
}

.row {
    width: 100%;
    display: flex;

    div {
        width: 50%;

        h6 {
            margin-bottom: 0.8rem;
        }

        &:first-of-type {
            margin-right: 2rem;
        }
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.column {
    @extend .row;
    width: calc(100% - 2rem);
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 1.4rem;
    }

    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }
}