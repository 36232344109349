@import "stylesheets/animations.scss";

.wrapper {
    width: 100%;
    animation: slideIn 0.5s;

    h4 {
        margin: 2rem 0 1rem 0;
    }
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.loadMore {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}