@import "stylesheets/animations.scss";

.banner {
    position: relative;
    width: 100%;
    max-width: 85rem;
    height: 35rem;
    margin: 10rem auto 0 auto;
    padding: 5rem;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow);
    animation: slideIn 0.4s;
    overflow: hidden;

    h3 {
        margin-bottom: 2rem;
        font-family: var(--font-family-serif);
        font-weight: 500;
        font-size: 3.5rem;
    }

    p {
        max-width: 55%;
        font-size: 1.8rem;
        color: var(--color-1);
    }

    img {
        position: absolute;
        width: 35rem;
        right: -5rem;
        bottom: -15rem;
    }
}

.availableSoon {
    @extend .banner;

    background-color: var(--color-8);
}