@import "stylesheets/animations.scss";

.activity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);
    animation: slideIn 0.4s forwards;
    font-size: 1.5rem;
    opacity: 0;

    > div {
        div:first-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    div:nth-child(2) {
        width: 22%;
    }

    div:nth-child(3) {
        width: 48%;
    }

    div:nth-child(4) {
        width: 20%;
    }

    &:nth-child(even) {
        background-color: var(--color-2-dimmed);
    }
}

.action {
    display: flex;
    align-items: center;

    span:last-child {
        margin-left: 1rem;
    }
}

.logo {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80%;
        height: auto;
    }
}

.netlifyLogo {
    @extend .logo;
}

.firebaseLogo {
    @extend .logo;

    img {
        width: 60%;
        height: auto;
    }
}

.spinner {
    width: 100%;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caption {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-2-dark);
}