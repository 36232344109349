.username {
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 4rem auto;

    h3,
    p {
        margin-bottom: 2rem;
        text-align: center;
    }

    img {
        width: 12rem;
        height: auto;
        margin: 2rem 0 4rem 0;
    }
}

.articles {
    width: 100%;

    h3,
    h3 +p {
        margin-bottom: 2.5rem;
    }

    ul {
        width: 100%;
        height: 35rem;
        margin-bottom: 2rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--color-2-darker);
        overflow: auto;

        h6,
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    li {
        display: flex;
        align-items: center;
        padding: 1.5rem;

        div {
            width: calc(100% - 6.1rem);
            margin-left: 1rem;
        }
    }

    p {
        font-size: 1.4rem;
    }
}

.spinner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;

    p {
        margin-top: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.error,
.success {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem 0 8rem 0;
    text-align: center;

    * {
        width: 80%;
    }

    h6 {
        margin-bottom: 2rem;
        font-size: 1.8rem;
    }
}

.error {
    * {
        color: var(--color-4);
    }
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button:last-child {
        margin-left: 2rem;
    }
}