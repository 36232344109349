@import "stylesheets/animations.scss";

.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    grid-gap: 4rem;
}

.site {
    width: 100%;
    max-width: 55rem;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    box-shadow: var(--shadow-card);
    transition: box-shadow 0.4s;
    animation: slideIn 0.4s forwards;
    opacity: 0;
    overflow: hidden;

    > span {
        font-size: 1.2rem;
        color: var(--color-2-dark);
    }
}

.meta {
    display: flex;
    align-items: center;
    margin: 4rem 0 3rem 0;

    h3 {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    p {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    div:last-child {
        width: calc(100% - 9rem);
    }
}

.avatar {
    width: 7rem;
    height: 7rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 500;
    color: var(--color-1);
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings {
    display: flex;
    cursor: pointer;

    span {
        width: 0.8rem;
        height: 0.8rem;
        display: block;
        margin-right: 0.4rem;
        border-radius: 50%;
        background-color: var(--color-2-darker);

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.title {
    margin-bottom: 2rem;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.column {
    width: 50%;

    h6 {
        margin-bottom: 0.7rem;
    }

    &:first-of-type {
        margin-right: 4rem;
    }
}

.create,
.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button:last-of-type {
        margin-left: 1rem;
    }
}

.create {
    margin-bottom: 4rem;
}