@import "stylesheets/animations.scss";

.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: var(--nav-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    animation: slideIn 0.4s forwards;
    animation-delay: 0.15s;
    opacity: 0;
    z-index: 999;
}

.wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.link {
    transition: color 0.2s;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-2-dark);
    cursor: pointer;

    &:not(:last-of-type) {
        margin-right: 2rem;
    }

    &:hover {
        color: var(--color-3);
    }
}

.logo {
    position: relative;
    width: calc(var(--sidebar-width) - 2rem);
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--horizontal-padding);
    border-right: 1px solid var(--color-2-darker);

    img {
        width: auto;
        height: 100%;
        margin: 0.5rem 2rem 0 0;
    }
}

.user {
    display: flex;
    align-items: center;
    text-align: right;
}

.details {
    margin-right: 1rem;
}

.name {
    font-size: 1.4rem;
    font-weight: 500;
}

.email {
    font-size: 1.2rem;
    color: var(--color-2-dark);
}