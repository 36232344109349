@import "stylesheets/animations";

.content {
    width: 100%;
    max-width: 40rem;
    margin: 15rem auto 0 auto;
    animation: slideIn 1s;

    h4,
    input {
        margin-bottom: 2rem;
    }
}

.success,
.error {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-5-light);
    text-align: center;
    font-weight: 500;
    color: var(--color-5);
}

.error {
    background-color: var(--color-4-light);
    font-size: 1.4rem;
    color: var(--color-4);
}

.notice,
.noticeValid {
    width: 100%;
    display: block;
    margin: -1rem 0 1.5rem 0;
    text-align: center;
    transition: color 0.2s;
    font-size: 1.2rem;
    color: var(--color-4);
}

.noticeValid {
    color: var(--color-5);
}