@import "stylesheets/animations.scss";

.container {
    width: 100%;
}

.title {
    display: flex;
    align-items: center;

    span {
        margin-left: 1rem;
    }
}

.description {
    margin: 1.5rem 0 2rem 0;
}

.webhooks {
    margin-bottom: 4rem;
}

.webhook {
    width: 100%;
    margin: 0 auto 1.5rem auto;
    padding: 2rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-darker);
    animation: slideIn 0.4s forwards;
    opacity: 0;
}

.webhookLabel {
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-2-dark);
}

.webhookTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.7rem;

    h6 {
        margin-right: 1rem;
    }
}

.webhookDelete {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    transition: background-color 0.4s, color 0.2s;
    color: var(--color-2-dark);
    cursor: pointer;

    &:hover {
        background-color: transparent;
        color: var(--color-4);
    }
}

.url {
    width: 100%;
    margin-bottom: 2.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: monospace;
    font-size: 1.8rem;
    color: var(--color-2-dark);
    overflow: hidden;
}

.events {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
}

.event {
    display: flex;
    align-items: center;

    > span {
        margin-left: 1rem;
    }
}

.modalTitle {
    margin-bottom: 2.5rem;
}

.row {
    width: 100%;
    margin-bottom: 1.5rem;

    h6 {
        margin-bottom: 0.8rem;
    }
}

.logo {
    width: auto;
    height: 3rem;
}

.webhookId {
    width: 100%;
    display: inline-block;
    margin-bottom: 2rem;
    padding: 1.5rem 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-3-lighter);
    text-align: center;
    word-wrap: break-word;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-3);
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button:last-child {
        margin-left: 1rem;
    }
}