.badge {
    display: inline-block;
    border-radius: 20px;
    letter-spacing: 1px;
    font-weight: bold;
}

.small {
    @extend .badge;
    line-height: 1.2rem;
    padding: 0.3rem 0.8rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.tiny {
    @extend .badge;
    line-height: 1rem;
    padding: 0.2rem 0.7rem;
    font-size: 1rem;
}

.neutral {
    background-color: var(--color-2-darker);
    color: var(--color-2-dark);
}

.info {
    background-color: var(--color-3-lighter);
    color: var(--color-3);
}

.success {
    background-color: var(--color-5-light);
    color: var(--color-5);
}

.danger {
    background-color: var(--color-4-light);
    color: var(--color-4);
}