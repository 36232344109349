.avatar {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: transform 0.2s, filter 0.2s;
    cursor: default;
}

.small {
    width: 3rem;
    height: 3rem;
}

.standard {
    width: 4rem;
    height: 4rem;
}

.selectable {
    width: 80px;
    height: 80px;
    margin-left: 15px;
    filter: grayscale(0.4);
    cursor: pointer;

    &:hover {
        filter: grayscale(0);
    }
}

.selected {
    transform: scale(1.15);
    filter: grayscale(0);
}

.large {
    width: 10rem;
    height: 10rem;
}

.stacked {
    margin-left: -1rem;
    box-shadow: 0 0 0 1px var(--color-2);

    &:first-of-type {
        margin-left: 0;
    }
}