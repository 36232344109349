@import "stylesheets/animations";

// Regular button

.button {
    min-width: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5rem;
    padding: 0 2rem;
    border-radius: var(--border-radius);
    background-color: var(--color-3);
    transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s, color 0.2s;
    text-align: center;
    color: var(--color-2);
    cursor: pointer;
    outline: none;

    &:focus {
        box-shadow: 0 0 0 4px var(--color-3-light);
    }

    &:active {
        transform: translateY(2px);
    }
}

.smaller {
    @extend .button;
    min-width: 12rem;
    height: 4rem;
    padding: 0 1.5rem;
}

.light {
    background-color: transparent;
    font-weight: 500;
    color: var(--color-3);

    &:hover {
        background-color: var(--color-3);
        color: var(--color-2);
    }
}

.danger {
    background-color: var(--color-4);
    font-weight: 500;
    color: var(--color-2);
}

.dangerLight {
    @extend .light;

    color: var(--color-4);

    &:hover {
        background-color: var(--color-4);
    }
}

// Wide button

.wideButton {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    padding: 0 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-3);
    transition: background-color 0.2s, color 0.2s;
    font-size: 2rem;
    color: var(--color-2);
    cursor: pointer;
    outline: none;

    span {
        margin-left: 1rem;
    }
}

.button:disabled,
.wideButton:disabled {
    background-color: var(--color-2-darker);
    transition: none;
    color: var(--color-2-dark);
    cursor: default;
}

//Spinner

.spinner,
.spinner:after {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    flex-shrink: 0;
}

.spinner {
    position: relative;
    margin-right: 0.5rem;
    border-top: 2px solid var(--color-2-dark);
    border-right: 2px solid var(--color-2-dark);
    border-bottom: 2px solid var(--color-2-dark);
    border-left: 2px solid var(--color-1);
    transform: translateZ(0);
    animation: rotate 1s infinite linear;
}