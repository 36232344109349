@import "../../Builder.module";

.containerWrapper {
    width: 100%;
}

.containerElements {
    width: 100%;
    margin-top: 2rem;
}

.containerBlock {
    display: flex;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-darker);
}