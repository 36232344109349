@import "stylesheets/animations";

// Layout
.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: fadeIn 0.7s;
}

.elements {
    width: 60%;
}

.block {
    display: flex;
    padding: 2rem;
    border-radius: var(--border-radius);
    //border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    box-shadow: var(--shadow);
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: 2rem;
}

.action {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    font-size: 1.7rem;
    color: var(--color-2-dark);
    cursor: grab;

    &:nth-child(n+2) {
        margin-top: 1rem;
        cursor: pointer;
    }
}

.actionSmall {
    @extend .action;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.4rem;
    cursor: pointer;

    &:nth-child(n+2) {
        margin-top: 0.5rem;
    }
}

.dropZone {
    width: 100%;
    height: 2.5rem;
    transition: height 0.4s;
}

.textArea {
    height: 20rem;
}

.devZone {
    position: relative;
    width: 100%;
    height: 2px;
    margin: 3.5rem 0 3rem 0;
    background-color: var(--color-2-darker);

    &:after {
        content: "Developer Zone";
        position: absolute;
        top: -0.8rem;
        left: 2rem;
        padding: 0 1rem;
        background-color: var(--color-2);
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button:first-of-type {
        margin-right: 1rem;
    }
}

.addElementButton {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: var(--color-3);
    font-size: 2rem;
    color: var(--color-2);
    cursor: pointer;
}

// Block element
.element {
    width: calc(100% - 4rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    h6 {
        margin: 0.7rem 0 0.5rem 0;
    }
}

.blockWrapper {
    width: 100%;
    display: flex;
}

.blockDetails {
    width: calc(100% - 4rem);
}

.blockType {
    margin-right: 1.5rem;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-3);
}

.icon {
    width: 8rem;
    height: 8rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--color-3-lighter);
    font-size: 5rem;
    color: var(--color-3);
    overflow: hidden;

    img {
        width: auto;
        height: 100%;
        object-fit: cover;
    }
}

.blockCaption {
    position: relative;
    width: calc(100% - 4rem);
    max-height: 8rem;
    font-size: 1.4rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden;

    * {
        font-size: 1.4rem;
        color: var(--color-2-dark) !important;
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 15rem;
        height: 2rem;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    }
}


// Element icon
.elementIcon {
    width: 100%;
    height: 12.5rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-3-lighter);
    text-align: center;
    cursor: pointer;

    div {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 1rem auto;
        border-radius: 50%;
        background-color: var(--color-3);
        font-size: 2.5rem;
        color: var(--color-3-lighter);
    }

    p {
        margin-top: 0.5rem;
        font-size: 1.2rem;
    }
}


// Element settings
.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 4rem;
    }
}

.fullWidthColumn,
.singleColumn,
.column {
    width: 50%;

    h6 {
        margin-bottom: 0.7rem;
    }

    &:first-of-type {
        margin-right: 2rem;
    }
}

.singleColumn:first-of-type {
    margin-right: 0;
    padding-right: 1rem;
}

.fullWidthColumn {
    width: 100%;

    &:first-of-type {
        margin: 0;
    }
}

// Media
.mediaItems {
    width: 100%;
    height: 22rem;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-darker);
    overflow: scroll;

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
        grid-gap: 2rem;
        animation: fadeIn 0.4s;
    }
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// YouTube
.youtubeVideoContainer {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    padding-top: 56.25%;
    overflow: hidden;
    z-index: 1;

    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 9;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 1;
    }
}