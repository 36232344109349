@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-extrabold-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'metropolis';
    src: url('../assets/fonts/metropolis-black-webfont.woff2') format('woff2'),
    url('../assets/fonts/metropolis-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

:root {
    --color-1: #071840;

    --color-2: white;
    --color-2-dimmed: #f5f7fb;
    --color-2-darker: #E5ECF9;
    --color-2-dark: #5B6F8C;

    --color-3-light: #F4F8FE;
    --color-3-lighter: #E5ECF9;
    --color-3: #071840;

    --color-4-light: #FFE5EE;
    --color-4: #FF0055;

    --color-5-light: #E5FFF8;
    --color-5-lighter: #DAFCC9;
    --color-5: #36d9d8;
    --color-5-dark: #157270;

    --color-6: #c182ff;

    --color-7: #6890fe;

    --color-8: #fec127;
    --color-8-gradient: radial-gradient(circle at top left, #f7d070, #fec127);

    --color-9: #f6ace9;

    --color-10-lighter: #fff1ea;
    --color-10: #fea686;
    --color-10-darker: #ff8441;

    --max-width: 135rem;
    --max-width-narrow: 80rem;
    --sidebar-width: 22rem;
    --panel-width: 28rem;
    --nav-height: 6rem;
    --horizontal-padding: 2rem;

    --border-radius: 5px;
    --border-radius-large: 2rem;

    --shadow: var(--color-2-darker) 0 5px 20px 0;
    --shadow-medium: 0 5px 10px -2px rgba(7, 24, 65, 0.25), 0 5px 7px -2px rgba(0, 0, 0, 0.3);
    --shadow-card: var(--color-2-darker) 0 4px 17px 5px;

    --font-family-serif: 'Vollkorn', serif;
}

html {
    box-sizing: border-box;
    font-size: 50%;
}

body {
    position: relative;
    display: block;
    margin: 0;
    background-color: white;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    color: var(--color-1);
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: 'metropolis', sans-serif;
}

[hidden] {
    display: none !important;
}

main {
    width: 100%;
    max-width: var(--max-width);
    min-height: 100%;
    display: block;
    margin: 0 auto;
    padding: 8.5rem var(--horizontal-padding) 10rem var(--horizontal-padding);
    background-color: var(--color-2);
    /*overflow: hidden;*/
}

button,
input {
    border: none;
    background: none;
    font-size: unset;
}

h1 {
    margin: 0 0 0.5rem 0;
    font-size: 3.5rem;
}

h2 {
    margin: 0;
    font-size: 3rem;
}

h3 {
    margin: 0;
    font-size: 2.5rem;
}

h4 {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

h5 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

h6 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
}

hr {
    border: none;
    border-top: 1px solid var(--color-2-darker);
}

a {
    background-color: transparent;
    transition: color 0.2s;
    text-decoration: none;
    font-weight: 500;
    color: var(--color-1);
}

a:active,
a:focus {
    color: var(--color-1);
}

b,
strong {
    font-weight: bolder;
}

nav {
    padding: 0 2rem;
}

iframe {
    border: none;
    border-radius: var(--border-radius);
}

img {
    border-style: none;
}

p {
    line-height: 1.4;
    color: var(--color-2-dark);
}

p,
ul {
    margin: 0;
    padding: 0;
}

pre {
    margin: 0;
}

li {
    list-style-type: none;
}

@media screen and (min-width: 350px) {
    html {
        font-size: 55%;
    }
}

@media screen and (min-width: 992px) {
    :root {
        --horizontal-padding: 4rem;
    }

    html {
        font-size: 62.5%;
    }

    main {
        padding: 8.5rem var(--horizontal-padding) 10rem var(--horizontal-padding);
    }
}