@import "stylesheets/animations";

.wrapper {
    width: calc(100% - var(--panel-width));
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: fadeIn 0.4s;
}

.content {
    width: 100%;
}

.info {
    width: 100%;
    max-width: 77rem;
    margin: -1rem 0 2.5rem 0;
}

.upload {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    grid-gap: 2rem;
    margin: 1.5rem 0;
}

.failed,
.active,
.item {
    width: 100%;
    height: 15rem;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-2-dimmed);
    transition: background-color 0.4s, color 0.4s;
    animation: fadeIn 0.7s;
    cursor: pointer;

    p {
        width: 100%;
        transition: color 0.2s;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
        overflow: hidden;
    }

    &:hover p {
        color: var(--color-1);
    }
}

.failed {
    background-color: var(--color-4-light);
    cursor: default;

    .icon,
    .progress {
        background-color: var(--color-4);
        color: var(--color-4-light);

        div {
            background-color: var(--color-4);
        }
    }

    p,
    &:hover p {
        color: var(--color-4);
    }
}

.active {
    background-color: var(--color-2-darker);

    p,
    &:hover p {
        color: var(--color-1);
    }
}

.icon {
    width: 6.5rem;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem auto;
    border-radius: 50%;
    background-color: var(--color-2-dark);
    text-align: center;
    font-size: 3.5rem;
    color: var(--color-2-darker);
    overflow: hidden;

    object,
    img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
    }
}

.progress {
    width: 100%;
    height: 0.5rem;
    margin: 0.4rem 0;
    border-radius: 2rem;
    overflow: hidden;

    div {
        height: 100%;
        background-color: var(--color-3);
        transition: width 0.4s;
    }
}

.size {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1.4rem;
}

.itemDetails {
    width: 100%;
    animation: fadeIn 0.4s;

    h6 {
        width: 100%;
        margin-bottom: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--color-2-dark);
        overflow: hidden;
    }

    p {
        font-size: 1.4rem;
    }
}

.previewImage {
    width: 100%;
    height: 15rem;
    margin: 1.5rem 0 1rem 0;
    border-radius: var(--border-radius);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: var(--shadow);
    background-color: var(--color-2-darker);
}

.urlReference {
    margin: 1.5rem 0 0.5rem 2px;
    font-size: 1.2rem;
    font-weight: 500;
}

.urlWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.url {
    width: 75%;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-2-darker);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    color: var(--color-1);
    overflow: hidden;
}

.copyButton {
    width: 20%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--color-2-darker);
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-3);
    cursor: pointer;
}

.delete {
    margin-top: 2.5rem;
}