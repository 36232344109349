@import "stylesheets/animations.scss";

.spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12rem 0;
}

.list {
    width: 100%;
    margin-top: 1rem;
}

.user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid var(--color-2-darker);
    animation: slideIn 0.4s forwards;
    opacity: 0;

    .column {
        width: 100%;
        max-width: 20rem;
        margin-left: 2.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .role {
        width: 100%;
        max-width: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        margin-left: 2.5rem;
    }

    &:last-of-type {
        border-bottom: none;
    }
}

.addUser {
    display: block;
    margin-top: 5rem;
}

.addWrapper {
    width: 100%;
    max-width: 55rem;
    display: flex;
    margin-top: 3rem;
}

.roleDropdown {
    width: 15rem;
    flex-shrink: 0;
    margin: 0 2rem;
}