.toolbar {
    width: 60%;
    display: flex;
    margin-bottom: 2rem;
}

.icon {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    transition: color 0.2s;
    font-size: 1.7rem;
    color: var(--color-2-dark);
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 1.5rem;
    }

    &:hover {
        color: var(--color-1);
    }
}