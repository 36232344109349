@import "stylesheets/animations";

.content {
    width: 100%;
    height: 100vh;
    background-color: var(--color-2);
    overflow: auto;
    z-index: 99;

    main {
        animation: slideIn 1s forwards;
    }
}

.spinner {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}