.dangerZone {
    position: relative;
    width: 100%;
    height: 2px;
    margin: 5rem 0 3rem 0;
    background-color: var(--color-4-light);

    &:after {
        content: "Danger Zone";
        position: absolute;
        top: -9px;
        left: 2rem;
        height: 2rem;
        padding: 0 1rem;
        background-color: var(--color-2);
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--color-4);
    }
}