@import "stylesheets/animations.scss";

.authentication {
    width: 100%;
    max-width: 170rem;
    height: 100%;
    min-height: 100vh;
    display: flex;
    margin: 0 auto;
}

.container {
    width: 100%;
    padding: 2.5rem;
}

.banner {
    width: 50%;
    display: none;
    padding: 4rem;

    div {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 7rem;
        border-radius: var(--border-radius-large);
        background-color: var(--color-8);
        background-image: var(--color-8-gradient);
        animation: slideIn 0.4s forwards;
        animation-delay: 0.2s;
        opacity: 0;
        overflow: hidden;

        h1 {
            margin-bottom: 2rem;
            font-family: var(--font-family-serif);
            animation: slideInRight 0.8s forwards;
            animation-delay: 0.2s;
            opacity: 0;
        }

        h4 {
            max-width: 40rem;
            line-height: 3rem;
            animation: slideInRight 0.8s forwards;
            animation-delay: 0.3s;
            color: var(--color-1);
            opacity: 0;
        }
    }
}

.left {
    position: absolute;
    bottom: -4rem;
    left: -5rem;
    width: 45%;
    animation: slideIn 0.5s forwards;
    animation-delay: 0.35s;
    opacity: 0;
    z-index: 8;
}

.middle {
    position: absolute;
    right: 0;
    bottom: -7rem;
    left: 0;
    width: 55%;
    margin: 0 auto;
    animation: slideIn 0.9s forwards;
    animation-delay: 0.25s;
    opacity: 0;
    z-index: 9;
}

.right {
    position: absolute;
    right: -5rem;
    bottom: -4rem;
    width: 45%;
    animation: slideIn 0.7s forwards;
    animation-delay: 0.45s;
    opacity: 0;
    z-index: 8;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8rem;

    a {
        padding: 1rem 1.5rem;
        border-radius: var(--border-radius);
        background-color: var(--color-2-dimmed);
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.content {
    width: 100%;
    max-width: 45rem;
    margin: 0 auto;

    > * {
        animation: slideIn 0.8s forwards;
        opacity: 0;
    }

    h1 {
        font-family: var(--font-family-serif);
    }

    h4 {
        font-size: 1.8rem;
        margin-bottom: 4rem;
    }

    h6 {
        margin: 0 0 0.7rem 0.4rem;
    }

    input {
        margin-bottom: 1.8rem;
    }
}

.logo {
    width: 8.5rem;
    height: auto;
    padding-top: 0.5rem;
    animation: slideIn 0.5s forwards;
}

.message {
    margin-bottom: 2rem;
    padding: 0.8rem 0.5rem;
    border-radius: var(--border-radius);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
}

.avatars {
    position: relative;
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    margin: 0 auto 2.4rem auto;
    padding-right: 1.5rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.avatarContainer {
    position: relative;
    user-select: none;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10px;
        z-index: 99;
    }

    &:before {
        left: 0;
        background: linear-gradient(90deg, var(--color-2) 0, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, var(--color-2) 100%);
    }
}

.chevronWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
    align-items: center;
    z-index: 100;
}

.chevronLeft {
    @extend .chevronWrapper;
    left: -15px;
}

.chevronRight {
    @extend .chevronWrapper;
    right: -15px;
}

.chevron {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.2s;
    font-size: 25px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-2-darker);
    }
}

.error {
    @extend .message;
    background-color: var(--color-4-light);
    color: var(--color-4);
}

.notification {
    @extend .message;
    background-color: var(--color-5-light);
    color: var(--color-5);
}

.passwordNotice {
    width: 100%;
    display: block;
    margin: -0.8rem 0 1.7rem 0.5rem;
    font-size: 1.2rem;
    color: var(--color-2-dark);
}

.resetPassword {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
}

.confirmBox {
    display: flex;
    align-items: center;
    margin: 1rem 0 2.5rem 0;
    font-size: 1.4rem;

    a {
        text-decoration: underline;
    }

    > span {
        margin-left: 1.5rem;
    }
}

.exit {
    animation: slideOut 0.7s forwards;
}

.exitDelayed {
    animation: slideOut 0.7s forwards;
    animation-delay: 0.15s;
}

@media screen and (min-width: 768px) {
    .banner {
        display: block;
    }

    .container {
        width: 50%;
        padding: 5.5rem;
    }

    .avatarContainer:before {
        left: 2rem;
    }

    .avatarContainer:after {
        right: 2rem;
    }

    .avatars {
        width: calc(100% - 4rem);
    }

    .chevronWrapper {
        display: flex;
    }
}