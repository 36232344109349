@import "stylesheets/animations.scss";

.container {
    width: 100%;
}

.flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.row {
    margin-bottom: 2rem;

    h6 {
        margin-bottom: 0.8rem;
    }
}

.wrapper {
    display: flex;
    align-items: center;
}

.avatar {
    position: relative;
    z-index: 8;

    img {
        width: 5.5rem;
        height: 5.5rem;
        flex-shrink: 0;
        margin-right: 2rem;
        border-radius: 50%;
        object-fit: cover;
        animation: scaleIn 0.4s;
    }

    &:hover .delete {
        transform: translateY(0);
        opacity: 1;
    }
}

.delete {
    position: absolute;
    top: -0.8rem;
    right: 0.8rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    transform: translateY(1rem);
    transition: transform 0.4s, opacity 0.4s, color 0.4s;
    box-shadow: var(--shadow-medium);
    opacity: 0;
    font-size: 1.8rem;
    color: var(--color-2-dark);
    cursor: pointer;
    z-index: 9;

    &:hover {
        color: var(--color-4);
    }
}


.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
}