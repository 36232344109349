.toast {
    position: fixed;
    right: 2rem;
    bottom: 5rem;
    padding: 2.5rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    backdrop-filter: blur(10px);
    transform: scale(0);
    transition: transform 0.5s;
    box-shadow: var(--shadow-card);
    color: var(--color-4);
    z-index: 99;

    h6 {
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
    }
}

.open {
    transform: scale(1);
}