@import "stylesheets/animations.scss";

.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius);
    animation: slideIn 0.4s forwards;
    opacity: 0;
}

.meta {
    width: 35%;
    display: flex;
    align-items: center;
    padding-right: 4rem;
}

.name {
    width: 100%;
    margin-left: 2rem;

    * {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    a:hover {
        color: var(--color-3);
    }

    p {
        font-size: 1.2rem;
    }
}

.lastUpdate {
    width: 20%;

    * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    p {
        font-size: 1.2rem;
    }
}

.unpublished,
.published {
    max-width: 12rem;
    display: flex;
    align-items: center;
    padding: 0.4rem 0.9rem;
    border-radius: 2rem;
    background-color: var(--color-10-lighter);
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-10);

    div {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: var(--color-10);
    }
}

.published {
    background-color: var(--color-5-light);
    color: var(--color-5);

    div {
        background-color: var(--color-5);
    }
}

.icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    transition: background-color 0.4s, color 0.2s;
    font-size: 1.7rem;
    color: var(--color-2-dark);
    cursor: pointer;

    &:hover {
        background-color: var(--color-2);
        color: var(--color-4);
    }
}