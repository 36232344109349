@import "stylesheets/animations.scss";

.confirmation {
    position: fixed;
    top: 2rem;
    right: 0;
    left: 0;
    width: 41rem;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    animation: slideIn 0.4s forwards;
    animation-delay: 0.4s;
    opacity: 0;
    z-index: 1000;
}