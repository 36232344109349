.panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--panel-width);
    height: 100%;
    min-height: 100vh;
    border-left: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
}

.wrapper {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 8rem 2rem 2rem 2rem;
    overflow: auto;
}