@import "stylesheets/animations.scss";

.metadata {
    animation: slideInRight 0.7s;

    h3 {
        width: 100%;
        margin-bottom: 2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 2.2rem;
        overflow: hidden;
    }

    h6 {
        margin-bottom: 0.5rem;
    }
}

.row {
    width: 100%;
    margin-bottom: 2.5rem;

    div {
        color: var(--color-2-dark);
    }
}

.flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.previewImage {
    width: 100%;
    height: 15rem;
    margin-bottom: 2.5rem;
    border-radius: var(--border-radius);
    background-position: center;
    background-size: cover;
    background-color: var(--color-2-darker);
    box-shadow: var(--shadow);
    cursor: pointer;
}

.items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-gap: 2rem;
    margin: 1.5rem 0;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}