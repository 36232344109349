.wrapper {
    position: relative;
    width: 100%;
    height: 38rem;
    margin: 2rem 0;
    -ms-overflow-style: none;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    margin-bottom: 2rem;
}

.categoryTitle {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    background-color: var(--color-2);
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button:first-of-type {
        margin-right: 1rem;
    }
}